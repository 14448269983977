@import 'K2OAuthenticator_variables.scss';


/** 
 * General Layout
 */ 
.k2oauthenticator .signin-method-item.email-auth {
  width: $emailAuthWidth;
  @include respond-max(md) {
    width: 100%;
  }
}
.k2oauthenticator .form-auth.email-auth {
  max-width: 40em;
}

/** 
 * Form (row/col) layout
 */
.k2oauthenticator .form-auth.email-auth .form-row .label-text {
  width: 90px;
}
.k2oauthenticator .form-auth.email-auth .form-row.wide .label-text {
  width: 150px;
}


/** 
 * Form nav links - for changing forms
 */ 
.k2oauthenticator .form-auth.email-auth .form-row .signin-helper-link:first-of-type {
  margin-top: 10px;
}
.k2oauthenticator .form-auth.email-auth .form-row .signin-helper-link {
  margin-top: 2px;
}


/**
 *  Various "Confirmation" Buttons
 *    Includes, sign-in, sign-up/create-account, forgot-pw, confirm-code, etc.
 */

/* General confirm-button styling */
.k2oauthenticator .form-auth.email-auth input.btn-sign-in {
  float: right;

  border-radius: 25px;
  line-height: normal;
  padding: .75em 1.125em;
  text-align: center;
  text-decoration: none;
  background-color: $color--defaultButtonTop;
  background-image: -webkit-gradient(linear,left top,left bottom,from($color--defaultButtonTop),to($color--defaultButtonBottom));
  background-image: linear-gradient(to bottom,$color--defaultButtonTop,$color--defaultButtonBottom);
  border: 1px solid #edb519;
  color: $color--defaultButtonText;
}

.k2oauthenticator .form-auth.email-auth input.btn-sign-in:hover {
  background-color: $color--defaultButtonBottom;
  background-image: -webkit-gradient(linear,left top,left bottom,from($color--defaultButtonBottom),to($color--defaultButtonTop));
  background-image: linear-gradient(to bottom,$color--defaultButtonBottom,$color--defaultButtonTop);
}

/* Button-Specific Tweaks, to optimize for diff form layouts */
.k2oauthenticator .form-auth.email-auth input#signin-button {
  margin-top: -48px;

  @include respond-max(md) {
    margin-top: $mobileEmailAuthBtn--marginTop;
  }
}
.k2oauthenticator .form-auth.email-auth input#signup-button {
  margin-top: -15px;

  @include respond-max(md) {
    margin-top: $mobileEmailAuthBtn--marginTop;
  }
}
.k2oauthenticator .form-auth.email-auth input#confirm-button {
  margin-top: -19px;

  @include respond-max(md) {
    margin-top: $mobileEmailAuthBtn--marginTop;
  }
}
.k2oauthenticator .form-auth.email-auth input#forgot-password-button {
  margin-top: -40px;

  @include respond-max(md) {
    margin-top: $mobileEmailAuthBtn--marginTop;
  }
}

