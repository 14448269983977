@import '../base';

.nav-utility {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;

  // Temp
  > * {
    margin-left: 1em;
  }
}

.nav-utility .locale-switcher {
	height: 50%;
}
.nav-utility .locale-switcher #lang-switcher {
	position: relative;
	margin-top: auto;
	/*bottom: 0px;*/
}
.nav-utility .header--current-user {
	position: relative;
	height: 50%;
	top: 6px;
}

