@import 'K2OAuthenticator_variables.scss';


/** 
 * General Layout
 */ 
.k2oauthenticator .signin-method-item.social-auth {
  width: $socialAuthWidth;

  @include respond-max(md) {
    width: 100%;
  }
} 


/** 
 * Bottom Row Content/Text
 */
.k2oauthenticator .form-auth.social-auth .form-row.ext-auth-notice {
  margin-top: 10px;
  color: #888;
}

/**
 * Social Auth Buttons
 */
.k2oauthenticator .form-auth.social-auth button {
  display: inline-block;
  background: $googleLightBlue;
  color: #fff;
  font-weight: 600;
  text-align: left;

  padding: 1px;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px gray;
  white-space: nowrap;

  flex-basis: 100%;
  margin-top: 20px;
}
.k2oauthenticator .form-auth.social-auth button:last-of-type {
  margin-bottom: 10px;
}
.k2oauthenticator .form-auth.social-auth button span.icon {
  border-radius: 2px;
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
.form-auth.social-auth button span.btn-text {
  font-family: $googleFontFamily;
  font-weight: 500;
}

/**
 * Google Auth Button
 */
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-google {
  background-color: $googleLightBlue;
}
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-google:hover {
  background-color: $googleLightBlueHover;
  color: #EEE;
}
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-google span.icon {
  background: #FFF 5px 50% no-repeat;
}
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-google span.icon .social-logo {
  height: 100%;
  padding: 9px;
  text-align: center;
  vertical-align: middle;
}

/**
 * Facebook Auth Button
 */
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-facebook {
  background-color: $facebookBlue;
}
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-facebook:hover {
  background-color: $facebookBlueHoverBackground;
  color: $facebookBlueHoverText;
}
.k2oauthenticator .form-auth.social-auth button#btn-ext-auth-facebook span.icon {
  background: 5px 50% no-repeat;
  padding: 6px;
}
