/*
	Breakpoint based visibility utility classes.
*/

@import '../utils/screen-readers';
@import '../utils/respond';


// a block item that is visible above the nav breakpoint
.visible-nav {
	display: none !important;

	& + .divider {
		margin-top: 0;
		@include respond(breakpointNav) {
			margin-top: 60px;
		}
	}
	@include respond(breakpointNav) {
		display: block !important;
	}
}

// an inline-block item that is visible above the nav breakpoint
.visible-nav-inline-block {
	display: none !important;
	@include respond(breakpointNav) {
		display: inline-block !important;
	}
}

.mobile-only {
	@include respond(breakpointNav) {
		display: none;
	}
}

.desktop-only {
	@include respond-max(breakpointNav) {
		display: none;
	}
}

