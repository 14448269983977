@import 'utils/respond';
@import 'utils/screen-readers';

// Thanks, Filament group.
@mixin select-styler {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-position: right .7em top 50%;
  background-repeat: no-repeat;
  background-size: .65em auto;
  border: 1px solid #aaa;
  border-radius: .2em;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  box-sizing: border-box;
  color: #444444;
  display: block;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  max-width: 100%;
  padding: .6em 1.4em .5em .8em;
  padding: .3em 1.9em .3em .8em;
  width: auto;
  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaaaaa;
    box-shadow: 0 0 1px 3px rgba(59,153,252,.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222222;
    outline: none;
  }
  & option {
    font-weight: normal;
  }
  &:disabled, &[aria-disabled=true] {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    color: graytext;
  }
  &:disabled:hover, &[aria-disabled=true] {
    border-color: #aaaaaa;
  }
}

