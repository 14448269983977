@import '../../base', 'K2OAuthenticator_variables.scss', 'K2OAuthenticator_ExternalAuth.scss', 'K2OAuthenticator_EmailAuth.scss';


/**
 * Auth Page Layout Section Wrapper/Container Styles
 */
.k2oauthenticator .signin-method-container {
  display: flex;
  
  padding: 0;
  margin: 0;
  list-style: none;

  @include respond-max(md) {
    flex-direction: column;
  }
}
.k2oauthenticator .signin-method-item {
  padding: 5px;
  width: 50%; /* override for diff layouts */
  height: 50%;
  margin-top: 10px;
  display: flex;
  flex-flow: column;

  @include respond-max(md) {
    width: auto;
  }
}
.k2oauthenticator .signin-method-divider-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-max(md) {
    flex-direction: row;
  }
}
.k2oauthenticator .signin-method-divider { 
  margin: 10px;
  color: #777;
}

.k2oauthenticator .block--questions {
  margin-top: 30px;
}


/***
 * Single Authentication Component Wrapper Styles
 */
.k2oauthenticator .form-auth {
  display: flex;
  flex-flow: row wrap;  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  border-radius: 5px;
  height: 100%;
  padding: 20px;
}

/* Each "row" within an auth component - esp. important for mobile */
.k2oauthenticator .form-row {
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
  align-items: center;

  @include respond-max(md) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.k2oauthenticator .form-row input {
  flex-grow: 1;

  @include respond-max(md) {
    width: 100%;
  }
}

.k2oauthenticator .form-auth h5 {
  padding: 0;
  margin: 0;
}

/* Top Content */
.k2oauthenticator-section-header {
  margin-top: 1em;
}

/* Bottom Content Links */
.k2oauthenticator .form-auth .form-row.auth-bottom-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;

  font-size: 0.85em;
}


/***
 * General form layout components
 */ 
.k2oauthenticator .form-auth div {
  flex-basis: 100%;
}
.k2oauthenticator .form-auth label {
  margin: 5px 0;
}
.k2oauthenticator .form-auth pre {
  text-align: center;
  white-space: break-spaces;
}
.k2oauthenticator .form-auth input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.k2oauthenticator .link-button {
  background-image: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.k2oauthenticator .link-button:hover,
.k2oauthenticator .link-button:focus {
  text-decoration: none;
}






