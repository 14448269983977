@import '../base';

/*corporate look & feel */
.main-content {
  border-bottom: 0 none;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

h3 {
  margin-top: 1em;
}

.next-link {
  margin-left: 300px;
}

.layout-primary {
  padding-top: 0;
}

.bold,
.strong {
  font-weight: bold;
}


/**
 *  Class Modifiers
 */ 
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.no-margin-sides {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-sides {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/**
 * Forms
 */

.form-item__textfield {
  padding: 0.75em;
}
.form-item--error {
  input {
    border: 1px solid $colorFormError;
  }
}
.input--pin {
  max-width: 100%;
  width: 26ch;
}
.input--address {
  max-width: 100%;
  width: 30ch;
  @include respond(md) {
    width: 40ch;
  }
}
.input--zip {
  width: 10ch;
  @include respond(md) {
    margin-left: 1em;
  }
}
.form-item--inline-group {
  @include respond(md) {
    display: flex;
    button {
      margin-left: 1em;
    }
  }
}

button[disabled],
html input[disabled] {
  cursor: not-allowed;
  background: lightgrey;
  color: darkgray;
  border-width: 0;
}


.locale-switcher,
.locale-switcher #lang-switcher {
  align-items: center;
  display: flex;

  &__label {
    margin-right: 1em;
  }

  &__select {
    @include select-styler;
  }
}

.btn-as-text {
  cursor: pointer;
}
