/*
  Helper classes
*/

@import '../utils/screen-readers';

// Hide visibly but keep accessible to screen readers:
.accessibility,
.sr-only {
	@include sr-only;
}

// Hide visibly and from screen readers
.hide,
.hidden {
	display: none;
}


.print-only {
	display: none;
}
