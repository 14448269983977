@import '../base';


$authDividerSpacing: 30px;
$leftBlockFlexBasis: calc(20% - #{$authDividerSpacing});
$blockSpacing: 1.25em;


.flex--sm {
  margin-top: 1em;
  @include respond(md) {
    margin-top: 2em;
    display: grid;
      grid-gap: 1em;
      /* Space between items */
      grid-template-columns: 2fr 2fr;
    .well {
      padding: 1.4em;
    }
  }
}


/**
 * Text Styling (H#s, P, etc.)
 */
#landing--layout--right h1,
#landing--layout--right h2,
#landing--layout--right h3,
#landing--layout--right h4
{
  padding-top: 0;
  margin-top: 0;
}


/****
 * Page Layout
 ***/
.image--banner {
  width: 100%;
}

#landing--layout--wrapper {
  padding: 14px 0;
  flex-wrap: wrap-reverse;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.column--wide {
  flex: 2;
}
.column--2 {
  padding-left: $ohsuColumnSpacing;
}




/** 
 *  Block: Partners
 */
 /* Temp fix until the class in the translation json can be changed. */
.block--partners + div > :first-child {
  border-top:1px dashed #e5e4e4;
  margin-top:1em;
  padding-top:1em;

  @include respond(nav) {
    margin-top:1.25em;
    padding-top:1.25em
  }
}
.block--partners.divider--dashed {
  margin-top: 0;
}
.block--partners.divider--dashed > h3 {
  margin-top: 0;
}
.page--landing .block--partners {
  margin-top: $blockSpacing;
}



/**** 
 *  Left Hand Column - Gray Boxes (oui-block, existing OHSU name)
 ***/
.oui-block {
    background-color: $lightGrey;
    margin-top: $blockSpacing;
    padding: $blockSpacing;
}

.oui-block h1,
.oui-block h2,
.oui-block h3,
.oui-block h4,
.oui-block h5 {
  margin-top: 0;
  padding-top: 0;
}
.oui-block p:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
}



/**** 
 *  Right Hand Column - Main Content
 ***/ 
.sign-in-wrapper-row {
  margin-top: 0;
  padding: 0;
}
.sign-in-wrapper-row.row {
  @include respond-max(md) {
    flex-direction: column !important;
  }
}
.signin-verified--content ul {
  padding-bottom: 0;
  margin-bottom: 0;
}

/**
 *  Section: Gray Boxes
 */
 /* Stack vertically for small widths */
.landing-gray-side-by-side {
  flex-direction: row;

  @include respond-max(md) {
    flex-direction: column;
  }
}
/* Remove gutter between columns for small widths */
.landing-gray-side-by-side .column--2 {
  @include respond-max(md) {
    padding-left: 0 !important;
  }
}
 

/**
 * Section: Create Account
 */ 
.block--create-account.column {
  flex-basis: $leftBlockFlexBasis;
}
.block--create-account button {
  /*font-weight: bold;*/
  align-self: flex-start;
}
.block--create-account .signin-verified--content {
  margin-top: 20px;
}
ul#list-verified-content {
  margin-top: 0;
  margin-bottom: $blockSpacing;
}

/**
 * Section: Sign In to existing account
 */ 
.block--existing-sign-in.column {
  border-left: 1px dashed $darkGreyOnDark;
  padding-left: $authDividerSpacing;

  @include respond-max(md) {
    padding-left: 0;
    border-left: 0;
    margin: $blockSpacing 0;
  }
}

/**
 * Section: Authed (i.e. My Surveys)
 */ 
.my-surveys-wrapper {
  margin-top: 1em;
}
