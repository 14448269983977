/* These styles aren't a part of the kyruss.css file. Generated from public site scss */

.divider--dashed{border-top:1px dashed #e5e4e4;margin-top:1em;padding-top:1em}
@media only screen and (min-width:60em){
  .divider--dashed{margin-top:1.25em;padding-top:1.25em}
}
.divider--dark{border-top:1px dashed #b9b5b5;margin-top:1em;padding-top:1em}
@media only screen and (min-width:60em){
  .divider--dark{margin-top:1.25em;padding-top:1.25em}
}
.divider--solid{border-top:1px solid #e5e4e4;margin-top:1em;padding-top:1em}
@media only screen and (min-width:60em){
  .divider--solid{margin-top:1.25em;padding-top:1.25em}
}
.divider{margin-top:3.75em}
.divider--small{margin-top:1.875em}
.divider--dashed-bottom{border-bottom:1px dashed #e5e4e4}
hr{border-color:#e5e4e4;border-style:solid;border-width:1px 0 0 0}
.notification--success,.notification-success{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.decorator-select .notification--error,.decorator-select .notification-error{margin:0}
.well{background-color:#f3f3f3;margin-bottom:2em;padding:2em}
.well::after,.well::before{content:"";display:table}
.well::after{clear:both}
.well--clear{background-color:transparent}
.well--highlight{background-color:#fdfde2;margin:2.5em 0;padding:1.5em}
.well--white-on-gray{background-color:#585e60;color:#fff}
.well--large{font-size:1.75em;line-height:1.39285;padding:40px}
.well--large h2,.well--large h3{font-size:150%}
.well :last-child {
  margin-bottom: 0;
}
.well :first-child {
  margin-top: 0;
}
.well :last-child {
  margin-bottom: 0;
}
.well--white-on-gray {
  * {
    color: #ffffff;
  }
}
.well--form-help {
  background: lighten($logo__green, 15%);
  background-color: mix($lightGray, $logo__green, $weight: 90%);
  padding: 1.2em;
}
