
////
/// @group colors
////

/// @type Color
$darkBlue: #0e4d8f;

/// @type Color
$lightBlue: #0072ff;

/// @type Color
$colorAction: $lightBlue;

/// @type Color
$linkColor: $lightBlue;

/// @type Color
$linkColor--hover: $darkBlue;

/// @type Color
$colorCopy: #555555;

/// @type Color
$colorHeading: #404040;

// Extracted from logo SVG
/// @type Color
$logo__yellow: #ffca38;

/// @type Color
$logo__blue: #5d97c9;

/// @type Color
$logo__green: #56b146;

/// @type Color
$logo__gray: #585e60;

/// @type Color
$form_item_sprite: #4174b9;

/// @type Color
$babyBlue: #a4cafa;

/// @type Color
$lightBabyBlue: #e8f2ff;

/// @type Color
$teal: #7399c8;

/// @type Color
$softYellow: #fdfde2;

/// @type Color
$softYellowGrey: #efefd0;

/// @type Color
$color--defaultButtonTop: #ffd769;

/// @type Color
$color--defaultButtonBottom: #ffc529;

/// @type Color
$color--defaultButtonText: #805b16;

/// @type Color
$color--defaultButtonBorder: #edb519;

/// @type Color
$green: #67b445;

/// @type Color
$green_alt: #417505; // from 2018 healthcare 'accepting patients' green

/// @type Color
$green__success_dot: #7ed321; // from 2018 healthcare 'accepting patients' green

/// @type Color
$colorPrimary: $darkBlue;

/// @type Color
$colorPrimaryDark: darken($colorPrimary, 10%);

/// @type Color
$colorSecondary: $lightBlue;

/// @type Color
$colorTertiary: #c34d36;

/// @type Color
$red: #c34d36;

/// @type Color
$colorAlert: $red;

/// @type Color
$color--alert__bg: $red;

/// @type Color
$color--alert__copy: #ffffff;

/// @type Color
$color-heading--lighter: #4a4a4a;

/// @type Color
$grey: #cccccc;

/// @type Color
$gray: $grey;

/// @type Color
$lightGrey: #f3f3f3;
$lightGray: $lightGrey;

/// @type color
$offWhite: #fafafa;

/// @type Color
$mediumGrey: #e5e4e4;

/// @type Color
$gray--map: #979797;

/// @type Color
$darkGreyOnDark: #b9b5b5;

/// @type Color
$darkGreyOnLight: #777777;

/// @type Color
$blueGrey: #717c87;

// New comp grays

$color--ohsu-gray: #2e2f30;

$color--footer__bg: $color--ohsu-gray;

// $color--mainnav__bg: $color--ohsu-gray; // when we switch to gray...
$color--mainnav__bg: $darkBlue;


// rgba(46, 47, 48, 0.79)
$color--mainnav__bg--scrolled: rgba($color--ohsu-gray, 0.79);

$color--home-callout_bg: $logo__gray;

$color--home-cta__bg: $lightGrey;

$color--home-cta__bg-icon: #5e97c9;




/*
FORMS
*/

/// @type Color
$colorFormBorder: $mediumGrey;

/// @type Color
$colorFormActiveBorder: $babyBlue;

/// @type Color
$colorPlaceHolder: $darkGreyOnDark;

/// @type Color
$colorFormError: $red;

/// @type Color
$colorFormRequiredIndicator: $red;

/*
Health care pages
*/

/// @type Color
$color--hc__page-background: #efefef;

/// @type Color
$color--hc__label: #5fa6d4;

/// @type Color
$color--hc__tray-background: #313131;

/// @type Color
$color--hc__light-grey: #dedede;

/// @type Color
$color--hc__link-color: #4a90e2;

/// @type Color
$color--hc__condition-banner-color: #000000;
