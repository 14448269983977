@import '../../colors';
@import '../../variables';

/**
 * Mobile (media query) Values
 */
$mobileEmailAuthBtn--marginTop: 15px;


/**
 * External/Social Auth
 */
$socialAuthWidth: 40%;

$googleFontFamily: Roboto, "Helvetica Neue", Arial, sans-serif;
$googleLightBlue: #4285F4;
$googleLightBlueHover: #3f7ce8;

$facebookBlue: #4267b2;
$facebookBlueHoverBackground: #2955ad;
$facebookBlueHoverText: #EEE;


/**
 * Email Auth
 */
$emailAuthWidth: 100% - $socialAuthWidth;